<template>
  <div class='declaration-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='declaration-title'>Declarations</div>
            <div class='declaration-stepper'>Step 6</div>
            <div class='declaration-checkbox-wrapper'>
              <span
                >I am Indian citizen born and residing in India.</span
              >
              <a-switch
                  size='small'
                  style='float: right; margin-top: 10px'
                  v-model:checked='indianCitizen'
                  checked-children="Yes" un-checked-children="No"
                />
            </div>
            <div class='declaration-checkbox-wrapper'>
              <span
                >I am not politically exposed or related.</span
              >
              <a-switch
                  size='small'
                  style='float: right; margin-top: 10px'
                  v-model:checked='politicallyExposed'
                  checked-children="Yes" un-checked-children="No"
                />
            </div>
            <div class='declaration-checkbox-wrapper'>
              <span style="width: 93%;"
                >Details of any action / activeProceedings / initiated / pending /
                 taken by SEBI against the application in last 3 years</span
              >
              <a-switch
                  size='small'
                  style='float: right;'
                  v-model:checked='activeProceedings'
                  checked-children="Yes" un-checked-children="No"
                />
            </div>
            <div class='declaration-checkbox-wrapper'>
              <a-checkbox v-model:checked='informationDeclaration'
                >I declare that all the information declared by me for account
                creation is completely true .</a-checkbox
              >
            </div>
            <div class='declaration-checkbox-wrapper'>
              <a-checkbox v-model:checked='annualIncomeDeclaration'
                >I declare that all my annual income (and networth) is not older
                than 1 year.
              </a-checkbox>
            </div>
            <div class='declaration-checkbox-wrapper'>
              <a-checkbox v-model:checked='accountAuthorization'
              :rules="[{ required: true, message: 'Please input group name!' }]"
                >Running authorization account for 90 days.</a-checkbox
              >
            </div>
            <div class='declaration-confirmation-btn-wrapper'>
              <a-button type='primary' @click='onClickContinue' :loading="loading"
                >Confirm and Proceed</a-button
              >
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
// import { CheckCircleTwoTone } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: {
    // CheckCircleTwoTone,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
        });
    });
    // const router = useRouter();
    const indianCitizen = ref(false);
    const politicallyExposed = ref(false);
    const activeProceedings = ref(false);
    const informationDeclaration = ref(false);
    const annualIncomeDeclaration = ref(false);
    const accountAuthorization = ref(false);

    const onClickContinue = () => {
      const data = {
        indianCitizen: indianCitizen.value,
        politicallyExposed: politicallyExposed.value,
        activeProceedings: activeProceedings.value,
        informationDeclaration: informationDeclaration.value,
        annualIncomeDeclaration: annualIncomeDeclaration.value,
        accountAuthorization: accountAuthorization.value,
      };
      if (indianCitizen.value) {
        loading.value = true;
        services
          .addDeclarations(data)
          .then((response) => {
            loading.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            // router.push('/nominee/add');
          })
          .catch(() => {
            loading.value = false;
          });
      } else {
        const message = 'Validation error';
        const description = 'Nationality declaration is mandatory';
        Operations.notificationError(message, description);
      }
    };

    return {
      onClickContinue,
      indianCitizen,
      politicallyExposed,
      activeProceedings,
      informationDeclaration,
      annualIncomeDeclaration,
      accountAuthorization,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/declarations.scss';
</style>
